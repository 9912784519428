// EstimateForm is used to populate preset values before submitting the form
//
// Usage:
//
// EstimateForm.init($('.js-estimate-form'));
//
import { MaterialForm } from "../common/material_form";
import { ScrollToElement } from "./scroll_to_element";

const EstimateForm = {
  init($container) {
    EstimateForm.populatePackagePreset($container, true);
    EstimateForm.showAddress($container);
    EstimateForm.showDimensions($container);
    EstimateForm.showSizeDimensions($container, true);
    EstimateForm.disableRequiredFieldValidations($container);

    $container.on("change", ".js-estimate-country .js-material-form__control", function() {
      EstimateForm.showAddress($container);
    });

    $container.on("change", ".js-package-select", function() {
      EstimateForm.populatePackagePreset($container, false);
      EstimateForm.showDimensions($container);
    });

    $container.on("change", ".js-package-type", function() {
      EstimateForm.showSizeDimensions($container, false);
    });

    // Prevent autocomplete on postal fields
    $container.find("input[autocomplete='postal-code']").attr("autocomplete", "new-password");

    // Remove default submit handler
    setTimeout(function() {
      $(".js-material-form").off("submit");
    }, 250);

    $container.on("submit", EstimateForm.handleSubmit);
  },

  disableRequiredFieldValidations($container) {
    // Disable client side validation for non CA/US postal code field as postal
    // code is not required for international addresses.
    $container.find("input#estimate_view_model_postal_code").removeAttr("data-validates-presence");

    // Disable street, city, province client side validations and let server
    // side validations handle it for now.
    $container.find(".js-custom-address-fields").find("input,select").removeAttr("data-validates-presence");
  },

  showAddress($container) {
    var $calculatorSuggestion = $container.find(".js-calculator-suggestion");
    var country = $container.find(".js-estimate-country .js-material-form__control").val();
    if (country == "CA" || country == "US" || country == "") {
      $container.find(".js-custom-address-fields").removeClass("custom-address-fields__visible");
      $calculatorSuggestion.show();
    } else {
      $container.find(".js-custom-address-fields").addClass("custom-address-fields__visible");
      $calculatorSuggestion.hide();
    }
  },

  showDimensions($container) {
    var $customPackageFields = $container.find(".js-custom-package-fields");
    var $packageSelect = $container.find(".js-package-select");
    var $packageSelectItem = $packageSelect.find(":selected");
    if ($packageSelectItem.data("is-custom")) {
      $customPackageFields.show();
    } else {
      $customPackageFields.hide();
    }
  },

  showSizeDimensions($container) {
    var $packageType = $container.find(".js-package-type").val();
    var $dimensionFields = $container.find(".js-custom-package-dimensions");
    var $sizeXAmount = $container.find(".js-size-x-amount");
    var $sizeYAmount = $container.find(".js-size-y-amount");
    var $sizeZAmount = $container.find(".js-size-z-amount");

    if ($packageType == "thick_envelope" || $packageType == "parcel") {
      $dimensionFields.show();
    } else {
      $dimensionFields.hide();
      // Enter holder value to pass validation
      if (!$sizeXAmount.val()) {
        $sizeXAmount.val("10").parent().addClass("is-focused");
      }
      if (!$sizeYAmount.val()) {
        $sizeYAmount.val("10").parent().addClass("is-focused");
      }
      if (!$sizeZAmount.val()) {
        $sizeZAmount.val("10").parent().addClass("is-focused");
      }
    }
  },

  populatePackagePreset($container, loading) {
    var $packageSelect = $container.find(".js-package-select");
    var $packageSelectItem = $packageSelect.find(":selected");
    var $packageType = $container.find(".js-package-type");
    var $weightAmount = $container.find(".js-weight-amount");
    var $weightUnit = $container.find(".js-unit-weight");
    var $sizeXAmount = $container.find(".js-size-x-amount");
    var $sizeYAmount = $container.find(".js-size-y-amount");
    var $sizeZAmount = $container.find(".js-size-z-amount");
    var $sizeUnit = $container.find(".js-unit-size");

    $(".js-estimate-form__empty-dropdown .is-present").removeClass("is-present");

    // Add presets if not custom, or if custom and page is not on initial load
    if (($packageSelectItem.data("is-custom") && !loading) || !$packageSelectItem.data("is-custom")) {
      $packageType.val($packageSelectItem.data("package-type"));
      $weightAmount.val($packageSelectItem.data("weight-amount"));
      $weightUnit.val($packageSelectItem.data("weight-unit"));
      $sizeXAmount.val($packageSelectItem.data("size-x-amount"));
      $sizeYAmount.val($packageSelectItem.data("size-y-amount"));
      $sizeZAmount.val($packageSelectItem.data("size-z-amount"));
      $sizeUnit.val($packageSelectItem.data("size-unit"));
    }
  },

  handleSubmit(event) {
    if (MaterialForm.validateForm($(this))) {
      $(".js-estimate-form__loader").addClass("d-flex");
      return true;
    }

    // Scroll to first invalid elment
    var firstElement = $(".is-invalid").first();
    if (firstElement) {
      ScrollToElement.scroll(firstElement, 1000);
    }

    event.preventDefault();
    event.stopPropagation();

    return false;
  }
};

export { EstimateForm };

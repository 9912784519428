/* eslint-disable no-invalid-this */
const MainNavigation = {
  init() {
    document.addEventListener("turbolinks:load", function() {
      let $nav = $(".js-navbar--main-nav");
      let $toggle = $nav.find(".js-menu-toggle");
      let $navBox = $nav.find(".js-main-nav");

      // Open/close menu
      $toggle.on("click", function() {
        $navBox.toggleClass("main-nav--show");
        $toggle.toggleClass("navbar-toggler--open");
        if ($toggle.attr("aria-expanded") == "false") {
          $toggle.attr("aria-expanded", "true");
        } else {
          $toggle.attr("aria-expanded", "false");
        }
      });

      // Open/close submenu items
      $navBox.find(".js-main-nav__item--haschildren > span").on("click", function(e) {
        e.preventDefault();
        $(this).parent().toggleClass("main-nav__item--open");
      });

      // Keyboard support
      $(".js-main-nav__link").on("focus", function() {
        $(".js-main-nav__item").removeClass("focus");
        $(this).parent(".js-main-nav__item").toggleClass("focus");
      });

      $(".js-main-nav__item").on("mouseover", function() {
        $(".js-main-nav__item").removeClass("focus");
      });

      $(".js-main-nav__sub-link").on("focus", function() {
        $(this).closest(".js-main-nav__submenu").parent(".js-main-nav__item").addClass("focus");
      });

      $("main *").on("focus", function() {
        $(".js-main-nav__item").removeClass("focus");
      });
    });
  }
};

export { MainNavigation };

/* eslint-disable no-undef */
import { LocationMap_CreateList } from "./location_map/location_map_create_list";
import { LocationMap_CreateMarkers } from "./location_map/location_map_create_markers";

const LocationMap = {
  initMap() {
    let markers = [];
    let torontoLatitude = 43.642605;
    let torontoLongitude = -79.387035;
    let mapCenter = new google.maps.LatLng(torontoLatitude, torontoLongitude);

    let map = new google.maps.Map(document.getElementById("location-map"), {
      center: mapCenter,
      maxZoom: 11,
      zoom: 9
    });
    LocationMap_CreateMarkers.init(map, markers);
    LocationMap_CreateList.init(markers);
  }
};

export { LocationMap };

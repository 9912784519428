/* eslint-disable no-invalid-this */

// MaterialForm provides the Javascript needed to display and validate
// forms styled with MaterialFormBuilder forms.
//
// Required form markup:
//
// <form class="material-form js-material-form">
//   <div class="material-form__group js-material-form__group">
//     <div class="material-form__label">
//     <div class="material-form__input js-material-form__control">
//     <div class="material-form__select js-material-form__control">
//     <div class="material-form__select-decorator">
//     <div class="material-form__underline">
//     <div class="material-form__error js-material-form__error">
//
// Usage:
//
// MaterialForm.init($('.js-checkout-form'));
//
import { Validator } from "./validator";
import { TristateCheckbox } from "./tristate_checkbox";


const MaterialForm = {
  init($form) {
    $form.on("submit", MaterialForm.handleSubmit);

    $form.find(".js-material-form__control").each(function() {
      MaterialForm.initControl(this);
    });

    $form.find(".js-material-form__text-area").each(function() {
      MaterialForm.initTextArea(this);
    });

    $form.find(".js-material-form__password").each(function() {
      MaterialForm.initPasswordField(this);
    });

    // Refactor TristateCheckbox into MaterialForm
    TristateCheckbox.init($form);
    MaterialForm.focusInvalid($form);
  },

  initControl(control) {
    var $control = $(control);
    $control.on("focus", MaterialForm.handleFocus);
    $control.on("change", MaterialForm.handleChange);
    $control.on("input", MaterialForm.handleInput);
    $control.on("blur", MaterialForm.handleBlur);
    if ($control.attr("autofocus") == "autofocus") {
      MaterialForm.addFocus($control);
    }
    MaterialForm.refreshControl($control);
  },

  initTextArea(control) {
    // http://stackoverflow.com/a/25621277/265940
    MaterialForm.adjustHeightToFit(control); // Cannot use jQuery object.
    MaterialForm.refreshControl($(control));

    $(control).on("input", function() {
      MaterialForm.adjustHeightToFit(this);
    });
  },

  initPasswordField(control) {
    var $control = $(control);
    $("<span class='js-material-form__password-show material-form__password-show'>Show</span>")
      .insertAfter($control);

    $(".js-material-form__password-show").each(function() {
      $(this).on("click", MaterialForm.handlePasswordShow);
    });
  },

  adjustHeightToFit(control) {
    // Note: scrollHeight = 0 on pageload. Wait for scrollHeight to be available
    // before updating the height of textarea
    setTimeout(function() {
      $(control)
        .css({ "height": "auto", "overflow-y": "hidden" })
        .height(control.scrollHeight);
    }, 1);
  },

  handleFocus(event) {
    var $control = $(event.target);
    MaterialForm.addFocus($control);
  },

  addFocus($control) {
    var $group = $control.parents(".js-material-form__group");
    $group.addClass("is-focused");
  },

  handleBlur(event) {
    var $control = $(event.target);
    $control.parent(".js-material-form__group").removeClass("is-focused");

    if ($control.data("materialFormChanged")) {
      $control.data("materialFormChanged", false);
      MaterialForm.refreshControl($control);
      MaterialForm.validateControl($control, false);
    }
  },

  handleChange(_event) {
    var $control = $(this);

    $control.data("materialFormChanged", false);
    MaterialForm.refreshControl($control);
    MaterialForm.validateControl($control, false);
  },

  handleInput(_event) {
    var $control = $(this);
    $control.data("materialFormChanged", true);
    MaterialForm.validateControl($control, true);
  },

  handlePasswordShow() {
    var $control = $(this).siblings(".js-material-form__password");

    if ($control.attr("type") === "password") {
      $control.attr("type", "text");
      $(".js-material-form__password-show").text("Hide");
    } else {
      $control.attr("type", "password");
      $(".js-material-form__password-show").text("Show");
    }
  },

  refreshControl($control) {
    var $group = $control.parents(".js-material-form__group");
    if ($control.val()) {
      $group.addClass("is-present");
    } else {
      $group.removeClass("is-present");
    }
    if ($control.hasClass("js-material-form__control--amount")) {
      MaterialForm.sanitizeAmount($control);
    }
  },

  sanitizeAmount($control) {
    var amount = $control.val().replace("$", "").replace(",", "");
    $control.val(amount);
  },

  handleSubmit(event) {
    var $form = $(event.target);
    if (MaterialForm.validateForm($form)) {
      return true;
    }

    // Scroll to first invalid elment
    var firstElement = $form.find(".is-invalid")[0];
    if (firstElement) {
      firstElement.scrollIntoView();
    }

    event.preventDefault();
    event.stopPropagation();
    return false;
  },

  validateForm($form) {
    var valid = true;
    $form.find(".js-material-form__control:not([disabled])").each(function() {
      if (!MaterialForm.validateControl($(this))) {
        valid = false;
      }
    });
    MaterialForm.focusInvalid($form);
    return valid;
  },

  validateControl($control, lengthOnly) {
    var message = null;
    if ($control.hasClass("js-material-form-disable-validation")) {
      return true;
    }
    if (lengthOnly) {
      message = Validator.validatesLengthMaximum($control, $control.data(), $control.val());
    } else {
      message = Validator.validateControl($control);
    }
    var $group = $control.parents(".js-material-form__group");
    if (message) {
      $group.addClass("is-invalid");
      $group.find(".js-material-form__error").text(message);
      $group.next(".material-form__hint").hide();
      return false;
    } else { // eslint-disable-line no-else-return
      $group.removeClass("is-invalid");
      $group.find(".js-material-form__error").text("");
      $group.next(".material-form__hint").show();
      return true;
    }
  },

  focusInvalid($form) {
    $form.find(".is-invalid .js-material-form__control").first().focus();
  }
};

export { MaterialForm };

/* eslint-disable no-invalid-this */
const InteractivePage = {
  init() {
    $(".js-interactive__back").on("click", function() {
      let $current_step = InteractivePage.getCurrentStep();

      if ($current_step.prev(".js-interactive__step").length) {
        InteractivePage.deactiveStep($current_step);
        // Go to choose destination page if have reached end
        if (InteractivePage.isDestinationStep($current_step) || InteractivePage.isFinalStep($current_step)) {
          InteractivePage.activateStep($(".js-interactive__step--6"));
        } else {
          InteractivePage.activateStep($current_step.prev(".js-interactive__step"));
        }
        InteractivePage.setBackButtonStatus();
        InteractivePage.setForwardButtonStatus();
        InteractivePage.incrementProgressBar(-1);
      }
    });

    $(".js-interactive__forward").on("click", function() {
      let $current_step = InteractivePage.getCurrentStep();
      $(".js-interactive__progress__status").removeClass("jitter");
      if ($current_step.next(".js-interactive__step").length) {
        if (InteractivePage.isReceivesStep($current_step) || InteractivePage.isDestinationStep($current_step)) {
          InteractivePage.activateStep($(".js-interactive__step--10"));
        } else {
          InteractivePage.activateStep($current_step.next(".js-interactive__step"));
        }

        InteractivePage.setBackButtonStatus();
        InteractivePage.setForwardButtonStatus();
        InteractivePage.incrementProgressBar(1);
        $(".js-interactive__progress__status").addClass("jitter");
      }
    });

    $(".js-interactive__us-button").on("click", function() {
      InteractivePage.activateStep($(".interactive__step--7"));
      InteractivePage.incrementProgressBar(1);
    });

    $(".js-interactive__canada-button").on("click", function() {
      InteractivePage.activateStep($(".interactive__step--8"));
      InteractivePage.incrementProgressBar(1);
    });

    $(".js-interactive__int-button").on("click", function() {
      InteractivePage.activateStep($(".interactive__step--9"));
      InteractivePage.incrementProgressBar(1);
    });

    $(".js-interactive__side-link").on("click", function() {
      InteractivePage.activateSideStep($(this));
    });

    $(".js-interactive__sidestep__back").on("click", function() {
      InteractivePage.deactiveSideStep($(this));
    });
  },

  activateSideStep($stepLink) {
    let sidestep = $stepLink.data("sidestep");

    $("#" + sidestep).addClass("interactive__step--active");
    $("#" + sidestep + " button").attr("tabindex", "1");
    $("#" + sidestep + " a").attr("tabindex", "1");
    $(".js-interactive__step--active button").attr("tabindex", "-1");
  },

  deactiveSideStep($stepLink) {
    let sidestep = $stepLink.data("sidestep");
    $(".js-interactive__sidestep").removeClass("interactive__step--active");
    $("#" + sidestep + " button").attr("tabindex", "-1");
    $("#" + sidestep + " a").attr("tabindex", "-1");
    $(".js-interactive__step--active button").attr("tabindex", "0");
  },

  setForwardButtonStatus() {
    let $current_step = InteractivePage.getCurrentStep();
    let $disable_button = InteractivePage.isFinalStep($current_step);

    if ($disable_button) {
      $(".js-interactive__forward").attr("disabled", true);
    } else {
      $(".js-interactive__forward").attr("disabled", false);
    }
  },

  setBackButtonStatus() {
    let $current_step = InteractivePage.getCurrentStep();
    let $disable_button = $current_step.hasClass("interactive__step--1");

    if ($disable_button) {
      $(".js-interactive__back").attr("disabled", true);
    } else {
      $(".js-interactive__back").attr("disabled", false);
    }
  },

  isSellingStep($current_step) {
    return $current_step.hasClass("interactive__step--3");
  },

  isPrintStep($current_step) {
    return $current_step.hasClass("interactive__step--4");
  },

  isTruckStep($current_step) {
    return $current_step.hasClass("interactive__step--5") ||
      $current_step.hasClass("interactive__step--6");
  },

  isReceivesStep($current_step) {
    return $current_step.hasClass("interactive__step--6");
  },

  isDestinationStep($current_step) {
    return $current_step.hasClass("interactive__step--7") ||
      $current_step.hasClass("interactive__step--8") ||
      $current_step.hasClass("interactive__step--9");
  },

  isFinalStep($current_step) {
    return $current_step.hasClass("interactive__step--10");
  },

  activateStep($step) {
    InteractivePage.deactivateTabs();
    $step.addClass("js-interactive__step--active")
      .addClass("interactive__step--active");
    InteractivePage.activateTabs();
  },

  deactiveStep($current_step) {
    InteractivePage.deactivateTabs();
    $(".js-interactive__progress__status").removeClass("jitter");
    $current_step
      .removeClass("js-interactive__step--active")
      .removeClass("interactive__step--active");
  },

  incrementProgressBar(value) {
    let $current_step = InteractivePage.getCurrentStep();
    let $progress_bar = $(".js-interactive__progress__status");
    let body_width = $(".interactive").width();
    let progress_width = parseFloat($progress_bar.css("width"), 2);
    let progress_value = progress_width + (value * parseFloat(1 / 6) * body_width);
    if (progress_value < body_width) {
      $progress_bar.css("width", progress_value);
    }
    if (InteractivePage.isDestinationStep($current_step) || InteractivePage.isFinalStep($current_step)) {
      $progress_bar.css("width", body_width);
    }

    InteractivePage.setProgressBarClass();
  },

  setProgressBarClass() {
    let $current_step = InteractivePage.getCurrentStep();
    let $progress_bar = $(".js-interactive__progress__status");

    $progress_bar.removeClass("interactive__progress__status--selling");
    $progress_bar.removeClass("interactive__progress__status--print");
    $progress_bar.removeClass("interactive__progress__status--truck");
    $progress_bar.removeClass("interactive__progress__status--final");

    if (InteractivePage.isSellingStep($current_step)) {
      $progress_bar.addClass("interactive__progress__status--selling");
    } else if (InteractivePage.isPrintStep($current_step)) {
      $progress_bar.addClass("interactive__progress__status--print");
    } else if (InteractivePage.isTruckStep($current_step)) {
      $progress_bar.addClass("interactive__progress__status--truck");
    } else if (InteractivePage.isDestinationStep($current_step) || InteractivePage.isFinalStep($current_step)) {
      $progress_bar.addClass("interactive__progress__status--final");
    }
  },

  maxOutProgressBar() {
    $(".js-interactive__progress__status").removeClass("jitter");
    $(".js-interactive__progress__status").css("width", "100vw");
    setTimeout(function() {
      $(".js-interactive__progress__status").addClass("jitter");
    }, 100);
    InteractivePage.setProgressBarClass();
  },

  deactivateTabs() {
    $(".interactive__step button").attr("tabindex", "-1");
    $(".interactive__step a").attr("tabindex", "-1");
  },

  activateTabs() {
    $(".js-interactive__step--active").last().find("button").attr("tabindex", "0");
    $(".js-interactive__step--active").last().find("a").attr("tabindex", "0");
  },

  getCurrentStep() {
    return $(".js-interactive__step--active").last();
  }
};

export { InteractivePage };

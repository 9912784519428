/* eslint-disable no-invalid-this */
const ModalPlay = {
  init() {
    document.addEventListener("turbolinks:load", function() {
      let trigger = $("body").find("[data-bs-toggle='modal']");
      trigger.click(function() {
        let theModal = $(this).data("bs-target");
        let videoSRC = $(this).attr("data-theVideo");
        let videoSRCauto = videoSRC + "?autoplay=1&showinfo=0&controls=0";
        $(theModal + " iframe").attr("src", videoSRCauto);

        // Stop video on close
        $(".modal").click(function(e) {
          if (e.target.class != "modal-dialog") {
            $(theModal + " iframe").attr("src", videoSRC);
          }
        });

        // Stop video on escape
        $(document).keyup(function(e) {
          if (e.key === "Escape") {
            if (e.target.class != "modal-dialog") {
              $(theModal + " iframe").attr("src", videoSRC);
            }
          }
        });
      });
    });
  }
};

export { ModalPlay };

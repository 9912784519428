/* eslint-disable no-invalid-this */

// Used to represent an attribute with three (or two) states.
//
const TristateCheckbox = {
  init($form) {
    var checked = "tristate-checkbox__state--checked";
    var mixed = "tristate-checkbox__state--mixed";
    var unchecked = "tristate-checkbox__state--unchecked";
    var focused = "tristate-checkbox__state--focused";

    $form.on("click", ".js-tristate-checkbox__state", function() {
      if ($(this).hasClass(checked)) {
        $(this).removeClass(checked).addClass(unchecked);
        $(this).next(".js-tristate-checkbox__radiobox")
          .children("input[value=no]")
          .prop("checked", true)
          .trigger("change");
      } else if ($(this).hasClass(mixed)) {
        $(this).removeClass(mixed).addClass(checked);
        $(this).next(".js-tristate-checkbox__radiobox")
          .children("input[value=yes]")
          .prop("checked", true)
          .trigger("change");
      } else {
        $(this).removeClass(unchecked).addClass(mixed);
        $(this).next(".js-tristate-checkbox__radiobox")
          .children("input[value=maybe]")
          .prop("checked", true)
          .trigger("change");
      }
    });

    $form.on("click", ".js-checkbox__state", function() {
      if ($(this).hasClass(checked)) {
        $(this).removeClass(checked).addClass(unchecked);
        $(this).next(".js-tristate-checkbox__radiobox")
          .children("input[value=no]")
          .prop("checked", true)
          .trigger("change");
      } else {
        $(this).removeClass(unchecked).addClass(checked);
        $(this).next(".js-tristate-checkbox__radiobox")
          .children("input[value=yes]")
          .prop("checked", true)
          .trigger("change");
      }
    });

    $form.on("keypress", ".js-checkbox-toggle", function(e) {
      e.preventDefault();
      var $parent = $(this).parent(".js-tristate-checkbox__radiobox");
      var focusState = $(this).val();

      if (e.which == 32) {
        if (focusState == "yes") {
          $parent.find(".js-checkbox-toggle[value=no]").prev("label").trigger("click").focus();
        }
        if (focusState == "yes" && $parent.find(".js-checkbox-toggle[value=maybe]").length) {
          $parent.find(".js-checkbox-toggle[value=maybe]").prev("label").trigger("click").focus();
        } else if (focusState == "no") {
          $parent.find(".js-checkbox-toggle[value=yes]").prev("label").trigger("click").focus();
        } else {
          $parent.find(".js-checkbox-toggle[value=no]").prev("label").trigger("click").focus();
        }
      }
    });

    $form.on("change", ".js-checkbox-toggle", function() {
      var focusState = $(this).val();
      var $parent = $(this).parent().prev();

      if (focusState == "yes") {
        $parent.removeClass(mixed + " " + unchecked).addClass(checked);
      } else if (focusState == "no") {
        $parent.removeClass(mixed + " " + checked).addClass(unchecked);
      } else {
        $parent.removeClass(checked + " " + unchecked).addClass(mixed);
      }
    });

    $form.on("focus", ".js-checkbox-toggle", function() {
      var $parent = $(this).parent().prev();
      $parent.addClass(focused);
    });

    $form.on("blur", ".js-checkbox-toggle", function() {
      var $parent = $(this).parent().prev();
      $parent.removeClass(focused);
    });
  }
};

export { TristateCheckbox };

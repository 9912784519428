const CapsLockOn = {
  init($input) {
    var $formWrapper = $input.parent().parent();
    $formWrapper.find(".material-form__hint").append("<div class=\"js-caps-lock__warning caps-lock-warning\"></div>");
    var capsLockWarning = $(".js-caps-lock__warning");

    $input.on("keyup", function(event) {
      if (event.originalEvent.getModifierState("CapsLock")) {
        capsLockWarning.text("Caps lock is on");
      } else {
        capsLockWarning.text("");
      }
    });
  }
};

export { CapsLockOn };

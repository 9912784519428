/* eslint-disable no-unused-vars */
const ScrollToElement = {
  scroll($element, duration) {
    if ($element.length) {
      $("html, body").animate({
        scrollTop: $element.offset().top
      }, duration);

      $element.attr("tabindex", "-1").focus();
    }
  }
};

export { ScrollToElement };

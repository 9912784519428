// Inspect the given control for data-validates-* attributes and the value of
// the control.  Return an error message if any validations fail else return null.
// Used by MaterialForm to validate controls.
const Validator = {
  validateControl($control) { // eslint-disable-line complexity
    var data = $control.data();
    var val = $control.val();

    return Validator.validatesPresence($control, data, val) ||
       Validator.validatesAcceptance($control, data, val) ||
       Validator.validatesLengthMinimum($control, data, val) ||
       Validator.validatesLengthMaximum($control, data, val) ||
       Validator.validatesNumericalityOnlyInteger($control, data, val) ||
       Validator.validatesNumericalityGreaterThanOrEqualTo($control, data, val) ||
       Validator.validatesNumericalityLessThanOrEqualTo($control, data, val) ||
       Validator.validatesNumericalityGreaterThan($control, data, val) ||
       Validator.validatesNumericalityLessThan($control, data, val) ||
       Validator.validatesEmailFormat($control, data, val) ||
       Validator.validatesEasyPostAddressField($control, data, val) ||
       Validator.validatesPostalCodeFormat($control, data, val) ||
       Validator.validatesCardNumberFormat($control, data, val) ||
       Validator.validatesCardCVCFormat($control, data, val) ||
       Validator.validatesCardExpiry($control, data, val);
  },

  validatesPresence($control, data, val) {
    if (data.validatesPresence && (val === null || val === false || val.trim() === "")) {
      return "Can't be blank";
    }
    return null;
  },

  validatesAcceptance($control, data, _val) {
    if (data.validatesAcceptance && !$control.is(":checked")) {
      return "Must be accepted";
    }
    return null;
  },

  validatesLengthMaximum($control, data, val) {
    var maxLength = $control.data("validates-length-maximum");
    var characterCount = val.length;
    if (maxLength > 0) {
      if (characterCount > maxLength) {
        return "Is too long (maximum is " + maxLength + " characters). At " + characterCount + " characters.";
      }
    }
    return null;
  },

  validatesLengthMinimum($control, data, val) {
    var minLength = data.validatesLengthMinimum;
    if (minLength && val.length < minLength) {
      return "Is too short (minimum is " + minLength + " characters)";
    }
    return null;
  },

  validatesNumericalityOnlyInteger($control, data, val) {
    if (data.validatesNumericalityOnlyInteger && !/^\d+$/.test(val.trim())) {
      return "Is not a number";
    }
    return null;
  },

  validatesNumericalityGreaterThanOrEqualTo($control, data, val) {
    var max = data.validatesGreaterThanOrEqualTo;
    if (max && parseFloat(val) < max) {
      return "Must be greater than or equal to " + max;
    }
    return null;
  },

  validatesNumericalityLessThanOrEqualTo($control, data, val) {
    var min = data.validatesLessThanOrEqualTo;
    if (min && parseFloat(val) > min) {
      return "Must be less than or equal to " + min;
    }
    return null;
  },

  validatesNumericalityGreaterThan($control, data, val) {
    var max = data.validatesGreaterThan;
    if (max && parseFloat(val) <= max) {
      return "Must be greater than " + max;
    }
    return null;
  },

  validatesNumericalityLessThan($control, data, val) {
    var min = data.validatesLessThan;
    if (min && parseFloat(val) >= min) {
      return "Must be less than " + min;
    }
    return null;
  },

  validatesEmailFormat($control, data, val) {
    // https://github.com/gshaw/common_validators/blob/master/app/validators/email_format_validator.rb#L17
    // eslint-disable-next-line max-len
    var EMAIL_REGEX = /^(([a-zA-Z0-9+_!#$%&'*+~=\/\{\}\|\`\^\?\-\.]+)@((?:[^(),:;<>@\[\\\]\s\.]+\.)+[a-z]{2,}))?$/i; // eslint-disable-line no-useless-escape

    if (data.validatesEmailFormat && !EMAIL_REGEX.test(val.trim())) {
      return "Is invalid";
    }
    return null;
  },

  validatesEasyPostAddressField($control, data, val) {
    if (data.validatesEasyPostAddressField && /[%&<>]/.test(val)) {
      return "Cannot contain % & < > characters";
    }
    return null;
  },

  validatesPostalCodeFormat($control, data, val) { // eslint-disable-line complexity
    if (data.validatesPostalCodeFormat) {
      var format = data.validatesPostalCodeFormat.toString().toLowerCase();
      var regex = null;
      var trimmedVal = val.trim();

      if (format == "us") {
        // US ZIP Code
        // http://stackoverflow.com/a/2577239/265940
        regex = /^\d{5}(?:[-\s]*\d{4})?$/;
      } else if (format == "ca") {
        // Canada Postal Code
        // http://stackoverflow.com/a/1146231/265940
        regex = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ]\s*[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/i;
      } else {
        // Rely on server side validation for international postal codes
        regex = null;
      }

      if (regex) {
        if (trimmedVal == "") {
          return "Can't be blank";
        }
        if (!regex.test(trimmedVal)) {
          return "Is invalid";
        }
      }
    }
    return null;
  },

  validatesCardNumberFormat($control, data, val) {
    if (data.validatesCardNumberFormat && !$.payment.validateCardNumber(val)) {
      return "Is invalid";
    }
    return null;
  },

  validatesCardCVCFormat($control, data, val) {
    if (data.validatesCardCVCFormat && !$.payment.validateCardCVC(val)) {
      return "Is invalid";
    }
    return null;
  },

  validatesCardExpiry($control, data, val) {
    // Year control selector passed as data value
    var year = $($control.data("validatesCardExpiry")).val();
    if (data.validatesCardExpiry && !$.payment.validateCardExpiry(val, year)) {
      return "Is expired";
    }
    return null;
  }
};

export { Validator };

// Handles business/personal account logic on signup account step
import Cookies from "js-cookie";

const SignupForm = {
  init($form) {
    var $radioButtons = $form.find(".signup-radio [type=radio]");

    SignupForm.resetForm($form);
    SignupForm.updateForm($form, $radioButtons);

    $radioButtons.change(function() {
      SignupForm.resetForm($form);
      SignupForm.updateForm($form, $radioButtons);
    });

    if (Cookies.get("ad_client")) {
      $form.find(".js-acquired-by-ad-id").val(Cookies.get("ad_client"));
    }
  },

  updateForm($form, $radioButtons) {
    var $selectedRadioButton = $radioButtons.filter(":checked");
    var selectedValue = $selectedRadioButton.val();
    if (selectedValue == undefined) {
      return;
    }

    $form.find(".signup-radio__form").removeClass("d-none");
    $form.find("[data-type='" + selectedValue + "']").removeClass("d-none");

    setTimeout(function() {
      $("html, body").animate({ scrollTop: $form.find(".signup-radio__form").offset().top }, 500);
    }, 200);
  },

  resetForm($form) {
    $form.find(".signup-radio__form").addClass("d-none");
    $form.find("[data-type]").addClass("d-none");
  }

};

export { SignupForm };

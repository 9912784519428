/* eslint-disable id-length, no-undef */
import Rails from "@rails/ujs";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
require("bootstrap");
require("select2");

Rails.start();
require("turbolinks").start();

// Global Functions
import { ModalPlay } from "../src/application/modal_play";
ModalPlay.init();
import { MainNavigation } from "../src/application/main_navigation";
MainNavigation.init();

// Inline Initialization
import { HomeSlider, TestimonialSlider } from "../src/application/slider";
import { LocationMap } from "../src/application/location_map";
import { CapsLockOn } from "../src/application/capslock_on";
import { ScrollToElement } from "../src/application/scroll_to_element";
import { SignupForm } from "../src/application/signup_form";
import { RequestPhoneVerificationForm } from "../src/application/request_phone_verification_form";
import { EstimateForm } from "../src/application/estimate_form";
import { InteractivePage } from "../src/application/interactive_page";
import { TrackingForm } from "../src/application/tracking_form";

window.HomeSlider = HomeSlider;
window.TestimonialSlider = TestimonialSlider;
window.LocationMap = LocationMap;
window.CapsLockOn = CapsLockOn;
window.ScrollToElement = ScrollToElement;
window.SignupForm = SignupForm;
window.RequestPhoneVerificationForm = RequestPhoneVerificationForm;
window.EstimateForm = EstimateForm;
window.InteractivePage = InteractivePage;
window.TrackingForm = TrackingForm;

const TrackingForm = {
  init($container) {
    $container.on("keypress change", ".js-material-form__text-area", function() {
      TrackingForm.convertCommaAndSpaceCharsToLineBreaks($container);
    });
  },

  convertCommaAndSpaceCharsToLineBreaks($container) {
    $container.find(".js-material-form__text-area").val(function(index, value) {
      return value.replace(/,|\s+/g, "\n");
    });
  }
};

export { TrackingForm };

import "slick-carousel";

const HomeSlider = {
  init() {
    $(".js-testimonials-slider").slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 9000,
      speed: 1000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    // Remove last 5 slides for mobile
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    mediaQuery.addListener(HomeSlider.handleMobile);
    HomeSlider.handleMobile(mediaQuery);
  },

  handleMobile(mediaQuery) {
    if (mediaQuery.matches) {
      $(".js-testimonials-slider").slick("slickRemove", 8);
      $(".js-testimonials-slider").slick("slickRemove", 7);
      $(".js-testimonials-slider").slick("slickRemove", 6);
      $(".js-testimonials-slider").slick("slickRemove", 5);
      $(".js-testimonials-slider").slick("slickRemove", 4);
    }
  }
};

const TestimonialSlider = {
  init() {
    $(".js-testimonials-slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 9000,
      speed: 1000
    });
  }
};

export { HomeSlider, TestimonialSlider };

const RequestPhoneVerificationForm = {
  init($container) {
    var $verifyViaSmsField = $container.find(".js-verify-via-sms-field");
    var $verifyViaCallField = $container.find(".js-verify-via-call-field");

    $container.on("click", ".js-submit-sms-button", function() {
      $verifyViaCallField.prop("disabled", "disabled");
      $verifyViaSmsField.prop("disabled", false);
    });

    $container.on("click", ".js-submit-call-button", function() {
      $verifyViaSmsField.prop("disabled", "disabled");
      $verifyViaCallField.prop("disabled", false);
    });
  }
};

export { RequestPhoneVerificationForm };
